// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-banner-notification-js": () => import("./../../../src/pages/banner_notification.js" /* webpackChunkName: "component---src-pages-banner-notification-js" */),
  "component---src-pages-banner-notification-single-title-js": () => import("./../../../src/pages/banner_notification_singleTitle.js" /* webpackChunkName: "component---src-pages-banner-notification-single-title-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-india-download-steps-js": () => import("./../../../src/pages/indiaDownloadSteps.js" /* webpackChunkName: "component---src-pages-india-download-steps-js" */),
  "component---src-pages-india-js": () => import("./../../../src/pages/india.js" /* webpackChunkName: "component---src-pages-india-js" */),
  "component---src-pages-paypal-404-js": () => import("./../../../src/pages/paypal/404.js" /* webpackChunkName: "component---src-pages-paypal-404-js" */),
  "component---src-pages-paypal-index-js": () => import("./../../../src/pages/paypal/index.js" /* webpackChunkName: "component---src-pages-paypal-index-js" */),
  "component---src-pages-paypal-result-js": () => import("./../../../src/pages/paypal/result.js" /* webpackChunkName: "component---src-pages-paypal-result-js" */),
  "component---src-pages-skrill-index-js": () => import("./../../../src/pages/skrill/index.js" /* webpackChunkName: "component---src-pages-skrill-index-js" */)
}

